import { css } from '@emotion/core';

export const breakpoints = {
  xl: '1580px',
  lg: '1280px',
  md: '960px',
  mdMin: '961px',
  sm: '600px',
  smMin: '601px',
  xs: '480px',
  xxs: '350px',
};

export const color = {
  black: '#1f1b1e',
  white: '#FFFFFF',
  transparentWhite1: 'rgba(255,255,255,0.15)',
  transparentWhite2: 'rgba(255,255,255,0.2)',
  transparentWhite3: 'rgba(255,255,255,0.3)',
  transparentWhite4: 'rgba(255,255,255,0.4)',
  transparentWhite7: 'rgba(255,255,255,0.7)',
  transparentWhite8: 'rgba(255,255,255,0.8)',
  transparent: 'transparent',
  darkGrey: '#282731',
  midGrey: '#4d4d4d',
  tooltipGrey: '#1e1e1e',
  textGrey: '#BBB',
  formGrey: '#2b2a2d',
  inputBg: 'rgba(255, 255, 255, 0.05)',
  inputBgFocus: 'rgba(255, 255, 255, 0.1)',
  labelColor: 'rgba(255, 255, 255, 0.5)',
  green: '#00c500',
  greenHover: '#04EA04',
  greenActive: '#00C300',
  yellow: '#ffe742',
  orange: '#f7bc00',
  lightOrange: '#eebe22',
  darkOrange: '#ff9e00',
  veryDarkOrange: '#ff7923',
  red: '#d7232e',
  shadedRed: '#900f0c',
  brightRed: '#e02020',
  midRed: '#460907',
  darkRed075: 'rgba(33,6,5,0.75)',
  darkRed1: '#340705',
  darkRed: '#210605',
  brownRed: '#3f0811',
  trueBlack: '#000',
  peach: '#ff7272',
  lightPeach: '#ffcdcd',
};

export const sizing = {
  default: '16px',
  text: '14px',
  small: '12px',
  large: '18px',
  huge: '44px',
  maxWidth: '1200px',
  width: '100%',
};

export const font = {
  default: ['Kanit', 'Arial', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(),
  heading: ['Kanit', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(),
  normalWeight: 400,
  mediumWeight: 500,
  headingWeight: 700,
  lineHeight: 1.25,
};

export const button = css`
  display: inline-block;
  color: ${color.black};
  background: ${color.orange};
  font-family: ${font.heading};
  font-size: 15px;
  font-weight: ${font.mediumWeight};
  letter-spacing: normal;
  line-height: 1;
  padding: 13px 23px;
  border: 1px solid ${color.orange};
  border-radius: 28px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  span {
    color: ${color.black};
  }

  &:hover {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border-color: ${color.white};
    color: ${color.white};
    text-decoration: none;
    span {
      color: ${color.white};
    }
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &.red-button {
    color: ${color.black};
    background: ${color.shadedRed};
    border-color: ${color.shadedRed};
  }
  &.red-button:hover,
  &.red-button:active,
  &.red-button:focus {
    background: ${color.textGrey};
    border-color: ${color.textGrey};
    color: ${color.black};
    span {
      color: ${color.black};
    }
    svg {
      path {
        fill: ${color.black} !important;
      }
    }
  }

  &.white-button {
    color: ${color.black};
    background: ${color.white};
    border-color: ${color.white};
  }
  &.white-button:hover {
    background: ${color.orange};
    border-color: ${color.orange};
    color: ${color.black};
    svg {
      path {
        fill: ${color.black} !important;
      }
    }
  }

  &.green-button {
    color: ${color.white};
    background: ${color.green};
    border-color: ${color.green};
    color: ${color.white};
    svg {
      path {
        fill: ${color.white} !important;
      }
    }
    span {
      color: ${color.white};
    }
  }
  &.green-button:hover {
    background: ${color.greenHover};
    border-color: ${color.greenHover};
  }
  &.green-button:active,
  &.green-button:focus {
    background: ${color.greenActive};
    border-color: ${color.greenActive};
  }
  &.transparent-button {
    background-color: transparent;
    border-color: ${color.transparentWhite7};
    color: ${color.white};
    span {
      color: ${color.white};
    }
  }
  &.transparent-button:hover,
  &.transparent-button:active,
  &.transparent-button:focus {
    background: linear-gradient(to left, #ff3e41, #ffc000);
    border: none;
    color: ${color.trueBlack};
    padding: 14px 23px;
    span {
      color: ${color.trueBlack};
    }
  }

  &.transparent-button-orange {
    background-color: transparent;
    border-color: ${color.darkOrange};
    color: ${color.darkOrange};
    span {
      color: ${color.darkOrange};
    }
  }
  &.transparent-button-orange:hover,
  &.transparent-button-orange:active,
  &.transparent-button-orange:focus {
    border-color: ${color.transparentWhite7};
    color: ${color.white};
    span {
      color: ${color.white};
    }
  }

  &.black-button {
    background: ${color.transparentWhite2};
    padding: 14px 35px;
    border: none;
    span {
      color: ${color.white};
    }
    svg {
      path {
        fill: ${color.white} !important;
      }
    }
  }
  &.black-button:hover,
  &.black-button:active,
  &.black-button:focus {
    background: ${color.transparentWhite1};
    border-color: ${color.transparentWhite1};
  }
  &.gradient-button {
    background-image: linear-gradient(
      to right,
      #ffc000 0%,
      #ff3e41 51%,
      #ffc000 100%
    );
    border: none;
    color: ${color.trueBlack};
    padding: 15px 35px;
    transition: 0.5s;
    background-size: 200% auto;
    span {
      color: ${color.trueBlack};
    }
  }
  &.gradient-button:hover,
  &.gradient-button:active,
  &.gradient-button:focus {
    background-position: right center;
    color: ${color.trueBlack};
    span {
      color: ${color.trueBlack};
    }
  }
  &.no-text {
    padding: 12px;
    svg {
      margin: 0;
    }
  }
`;

export const phoneInputStyles = css`
  .PhoneInput {
    position: relative;
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    padding: 5px 35px 0 15px;
    width: 70px;
    &::after {
      content: '';
      height: 20px;
      width: 1px;
      border-right: 1px solid ${color.white};
      position: absolute;
      right: 7px;
      top: 13px;
      opacity: 0.25;
    }
  }

  .PhoneInputInput {
    padding-left: 70px;
  }

  .PhoneInputCountryIcon {
    display: inline-block;
    max-width: 100%;
    svg {
      display: none;
    }
  }

  .PhoneInputCountryIcon--border {
  }

  .PhoneInputCountryIconImg {
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.5;
  }

  .PhoneInputInternationalIconGlobe {
    display: none;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border: 2px solid transparent;
    border-top-color: ${color.white};
    border-right-color: ${color.white};
    transform: rotate(135deg);
    opacity: 0.8;
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
  }
`;

export const formStyles = css`
  h2 {
    margin: 0 0 2rem;
    color: ${color.white};
  }
  .button {
    margin-right: 15px;
    border: none;
    ${button}
  }
  .button:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  .fieldWrap {
    margin-bottom: 5px;
    padding-bottom: 20px;
    position: relative;
    .error {
      position: absolute;
      left: 0;
      bottom: 3px;
      font-size: 12px;
      padding: 0 20px;
      color: ${color.labelColor};
      color: ${color.red};
      max-width: 50%;
    }
    .input-notice {
      position: absolute;
      left: 0;
      bottom: 3px;
      font-size: 12px;
      padding: 0 20px;
      color: ${color.labelColor};
    }
    .error-new {
      position: absolute;
      right: 4px;
      top: -18px;
      font-size: 12px;
      padding: 0 5px;
      color: ${color.red};
      background: none;
      font-weight: ${font.normalWeight};
    }
    .extra-placehodler {
      position: absolute;
      top: 14px;
      right: 40px;
      font-size: 14px;
      color: ${color.white};
      opacity: 0.4;
    }
    .error-new + input,
    .error-new + select,
    .error-new + textarea,
    .error-new + .PhoneInput input {
      border-color: ${color.red};
    }
    &.labelField {
      .extra-placehodler {
        top: 36px;
      }
      svg {
        top: 36px;
      }
      .error-new {
        top: 3px;
      }
    }
  }

  .inputName {
    color: ${color.transparentWhite4};
    font-size: 1rem;
  }

  .with-label .error {
    top: 37px;
  }

  .checkboxGroupWrap {
    position: relative;
    p {
      margin-bottom: 10px;
    }
    .minimal-custom-checkbox {
      margin-bottom: 0;
    }
  }

  .checkboxGroupWrap .error,
  .radioGroupWrap .error {
    position: absolute;
    right: 0;
    top: 5px;
    text-align: right;
    background: none;
    font-size: 12px;
    color: ${color.red};
  }
  label {
    padding: 0 20px 5px;
    display: block;
    cursor: pointer;
    color: ${color.labelColor};
    font-size: 12px;
    &.for-small {
      padding-left: 0;
      padding-bottom: 7px;
    }
  }
  .iconLabel {
    svg {
      vertical-align: baseline !important;
      margin-right: 5px;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  .PhoneInputInput {
    background: ${color.inputBg} !important;
    color: ${color.white} !important;
    line-height: 1.5;
    font-size: 16px;
    padding: 10px 20px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 24px;
    font-family: inherit;
    &.white-input {
      background: ${color.white} !important;
      color: ${color.midGrey} !important;
      border-color: ${color.white};
    }
  }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    border-color: ${color.white};
    background: ${color.inputBgFocus} !important;
    &.white-input {
      border-color: ${color.orange};
      background: ${color.orange} !important;
      color: ${color.black};
    }
    & + .icon {
      color: ${color.white};
    }
  }
  input + span,
  textarea + span {
    color: ${color.red};
    font-size: 12px;
    display: block;
    padding: 5px 5px 0;
  }
  textarea {
    min-height: 7rem;
    width: 100%;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${color.black} inset !important;
    -webkit-text-fill-color: ${color.white} !important;
    & + .icon {
      color: ${color.white};
    }
  }
  select {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: ${color.inputBg} !important;
    color: ${color.white} !important;
    line-height: 1.5;
    font-size: 16px;
    padding: 10px 40px 10px 20px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 24px;
    font-family: inherit;
    option {
      color: ${color.trueBlack} !important;
    }
  }
  select::-ms-expand {
    display: none;
  }
  .iconField {
    position: relative;
    input {
      padding-right: 48px;
    }
    svg,
    .icon {
      position: absolute;
      right: 16px;
      top: 14px;
      opacity: 0.6;
      pointer-events: none;
    }
    .icon-span {
      top: 10px;
    }
    .required-icon {
      font-size: 22px;
      line-height: 1;
      top: 12px;
    }
    .PhoneInput {
      & + .required-icon {
        color: ${color.red};
        right: 22px;
        top: 16px;
      }
    }
    .PhoneInput--focus {
      & + .required-icon {
        color: ${color.white};
      }
    }
  }

  .smallField {
    padding-bottom: 10px;
    svg,
    .icon {
      top: 8px;
      right: 10px;
    }

    select,
    input {
      line-height: 1.1;
      padding: 7px 15px;
      border-radius: 5px;
      border-color: ${color.transparentWhite1};
    }
    input {
      line-height: 16px;
      padding: 3px 15px;
    }
  }

  .minimal-custom-radio {
    .radio-wrap {
      margin-bottom: 10px;
    }
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid ${color.textGrey};
      border-radius: 100%;
      background: ${color.inputBg};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${color.yellow};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .minimal-custom-checkbox {
    margin-bottom: 10px;
    input {
      position: absolute;
      opacity: 0;
    }

    input + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 14px;
    }

    input + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
      background: ${color.inputBg};
      border: 1px solid ${color.transparentWhite7};
      border-radius: 2px;
    }

    input:hover + label:before {
      background: ${color.black};
    }

    input:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    input:disabled + label {
      color: ${color.white};
      cursor: auto;
    }

    input:disabled + label:before {
      box-shadow: none;
      background: ${color.formGrey};
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  .image-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    height: 34px;
    width: 100%;
    color: ${color.white};
  }

  .image-select__single-value {
    .image-option {
      border-top: none;
    }
    & + div {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 10px;
    }
  }

  .searchable-select__single-value {
    color: ${color.white};
  }

  .error-new + .styled-select .styled-select__control--is-focused,
  .error-new + .image-select .image-select__control--is-focused {
    border: none !important;
  }

  .error-new + .styled-select .styled-select__control,
  .error-new + .image-select .image-select__control {
    border: 1px solid ${color.red};
  }

  .selectField {
    height: 66px;
    overflow: visible;
    .styled-select__menu,
    .searchable-select__menu {
      position: relative;
      z-index: 2;
    }
    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      small {
        font-size: 12px;
        opacity: 0.7;
        text-transform: uppercase;
      }
    }
  }

  .selectFieldSmall {
    height: 33px;
    overflow: visible;
    z-index: 1;
    margin-bottom: 17px;
    .styled-select-small__menu {
      position: relative;
      z-index: 1;
    }
    .styled-select__menu-list {
      max-height: 160px;
    }
  }

  .form-bottom {
    .button {
      float: right;
    }
    .notice {
      font-size: 12px;
      color: ${color.transparentWhite2};
    }
  }

  .errorMsg {
    display: none;
    background: ${color.red};
    color: ${color.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    &.visible {
      display: block;
      margin-bottom: 25px;
    }
  }

  .successMsg {
    display: none;
    text-align: center;
    svg {
      filter: drop-shadow(1px 1px 5px #dc0101);
    }
    .successMsgText {
      color: ${color.white};
      font-weight: ${font.normalWeight};
    }
    p {
      margin: 10px 0;
      font-size: 14px;
    }
    &.visible {
      display: block;
      & + .notice,
      & + form,
      & + .notice + form {
        display: none;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    h2 {
      margin-top: 2rem;
    }
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
    }
  }
`;

export const reset = css`
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraBGwCYdA.woff2)
      format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
    font-display: swap;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcrabGwCYdA.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraVGwA.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWzVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
    font-display: swap;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWpVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWnVaE.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWzVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
    font-display: swap;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWpVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWnVaE.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWzVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
    font-display: swap;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWpVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWnVaE.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWzVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
    font-display: swap;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWpVaF5NQ.woff2)
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4yPRWnVaE.woff2)
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  html,
  body,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    text-rendering: optimizeLegibility;
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
  html,
  body {
    height: 100%;
  }
  body {
    background: ${color.trueBlack}
      radial-gradient(circle at 45% -12%, #8b0f0c, rgba(0, 0, 0, 0) 47%)
      no-repeat fixed center;
    color: ${color.white};
    margin: 0;
    font-family: 'Kanit', 'Helvetica Neue', Arial, sans-serif;
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${color.white};
  }
  ::placeholder {
    color: ${color.white};
    opacity: 0.4;
  }

  :-ms-input-placeholder {
    color: ${color.white};
    opacity: 0.4;
  }
  ::-ms-input-placeholder {
    color: ${color.white};
    opacity: 0.4;
  }

  input:focus::placeholder {
    color: transparent;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    body {
      scroll-behavior: auto;
    }
  }
`;

export const wrapper1200 = css`
  max-width: 1254px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const wrapper1650 = css`
  max-width: 1650px;
  margin: 0 auto;
`;

export const wrapper1224 = css`
  max-width: 1224px;
  margin: 0 auto;
`;

export const wrapper = css`
  margin: 0 auto;
`;

export const featuredWrapper = css`
  position: relative;
`;

export const noFeaturedWrapper = css`
  position: relative;
`;

export const typography = css`
  h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  h5,
  .h5,
  h6 {
    margin-top: 30px;
    color: ${color.orange};
    font-family: ${font.heading};
    font-weight: ${font.headingWeight};
    font-size: 22px;
    em,
    i,
    strong,
    b {
      font-weight: inherit;
    }

    &.rich-design {
      span {
        color: ${color.yellow};
        -webkit-text-fill-color: ${color.yellow};
        -webkit-text-stroke: 1px ${color.darkOrange};
        text-shadow: 1px 1px 1px ${color.darkOrange};
      }
    }
    &.big-header {
      font-size: 70px;
    }
  }
  .h1 {
    color: ${color.orange};
    font-size: 2rem;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.32);
    font-family: ${font.heading};
    font-weight: ${font.headingWeight};
    &.rich-design {
      span {
        color: ${color.yellow};
        -webkit-text-fill-color: ${color.yellow};
        text-shadow: 0 0 0 1px #ffab00;
        -webkit-text-stroke: 1px ${color.orange};
        text-shadow: 1px 1px 0 ${color.orange};
      }
    }
  }
  h1 + div {
    margin-top: 1.5rem;
  }
  h2,
  .h2 {
    margin: 2rem 0 1rem;
    text-align: left;
  }
  h3,
  .h3 {
    margin: 1.8rem 0 1rem;
    &.with-icon {
      color: ${color.white};
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
  }
  h4,
  h5,
  .h5,
  h6 {
    font-size: 1.2rem;
    margin: 1.5rem 0 1rem;
    + * {
      margin-top: 0.5rem;
    }
  }
  .subheader {
    color: ${color.white};
    font-size: 22px;
    font-weight: ${font.mediumWeight};
    letter-spacing: 0.75;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.32);
  }
  a {
    color: ${color.white};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      text-decoration: underline;
      color: ${color.darkOrange};
    }
  }
  ul {
    padding: 0;
  }
  li {
    margin-top: 0.5rem;
  }
  blockquote {
    padding: 30px 40px;
    background-color: ${color.red};
    font-weight: ${font.headingWeight};
    font-size: 1.8rem;
    border-radius: 24px;
    margin: 20px 0;
  }
  .button {
    ${button}
  }
  .screen-reader-text {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
  .white {
    color: ${color.white};
  }
  .orange {
    color: ${color.orange};
  }
  .a-center {
    text-align: center;
  }
  .a-left {
    text-align: left;
  }
  .a-right {
    text-align: right;
  }
  .white-stroke {
    border: 1px solid ${color.white};
  }
  .opacity-04 {
    opacity: 0.4;
  }
  .stroked {
    -webkit-text-stroke: 1px ${color.white};
    -webkit-text-fill-color: ${color.transparent};
  }
  .no-padding {
    padding: 0 !important;
  }
  .table-wrapper-borders {
    margin: 20px 0;
    border: 1px solid ${color.transparentWhite1};
    border-radius: 10px;
  }
  table {
    margin: 0;
    width: 100%;
    border: 0;
    border-collapse: collapse;
    border: 1px solid ${color.transparentWhite1};
    border-radius: 10px;
    border-bottom: none;
    overflow: hidden;
    td,
    th {
      text-align: left;
      padding: 15px 20px;
    }
    thead {
      background: ${color.darkRed1};
    }
    th {
      border-bottom: 1px solid ${color.darkRed1};
      font-weight: ${font.headingWeight};
    }
    td {
      border-top: 1px solid ${color.transparentWhite1};
      font-weight: ${font.normalWeight};
    }
  }

  .css-col-2 {
    column-count: 2;
    column-gap: 30px;
  }

  .css-col-3 {
    column-count: 3;
    column-gap: 30px;
  }

  .flex {
    &::before {
      display: none !important;
    }
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .col-sm-6 {
      -webkit-box-flex: 1;
      flex: 1 1 0;
      padding: 10px;
    }
    .col-4 {
      flex-basis: 24%;
      padding: 5px;
    }
    .col-3,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      flex-basis: 33%;
      padding: 10px;
      img {
        width: 100%;
      }
    }
    .col-2 {
      flex-basis: 49%;
      padding: 10px 20px;
    }
    .col-img {
      flex-basis: 30%;
    }
    .col-text {
      flex-basis: 67%;
    }
  }

  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .clear {
    clear: both;
  }

  .accordion {
    margin: 20px 0;
    &.no-margin {
      margin: 0;
    }
    &.accordion-open {
      .accordion-content {
        height: auto;
        display: block;
      }
      .accordion-title {
        &::after {
          transform: rotate(-135deg);
          top: 20px;
        }
      }
    }
  }
  .accordion-title {
    position: relative;
    border-radius: 25px;
    font-size: 1.2rem;
    font-weight: ${font.headingWeight};
    color: ${color.white};
    line-height: 1.1;
    padding: 15px 25px 15px 50px;
    cursor: pointer;
    &.half-width {
      max-width: 50%;
    }
    &::after {
      content: '';
      border: solid ${color.white};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      font-size: 20px;
      position: absolute;
      left: 20px;
      top: 15px;
      transform: rotate(45deg);
    }
    &:hover,
    &:active {
      color: ${color.darkOrange};
      &::after {
        border-color: ${color.darkOrange};
      }
    }
  }
  .accordion-content {
    height: 0;
    display: none;
    padding-top: 10px;
    transition: all 0.5s ease-in-out;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    .tooltip-title {
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      padding: 1px;
      border: 2px solid ${color.transparentWhite3};
      font-size: 12px;
      line-height: 1;
      border-radius: 10px;
      color: ${color.transparentWhite3};
    }
    .tooltip-content {
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 150px;
      margin-left: -75px;
      font-size: 12px;
      line-height: 1.1;
      background: ${color.tooltipGrey};
      padding: 15px;
      border-radius: 10px;
      transition: all 1s linear;
      display: none;
      opacity: 0;
      &::after {
        content: '';
        border: 8px solid transparent;
        border-top-color: ${color.tooltipGrey};
        position: absolute;
        bottom: -15px;
        left: 68px;
      }
    }
    .tooltip-content-left {
      left: auto;
      right: -9px;
      &::after {
        left: auto;
        right: 10px;
      }
    }
    .tooltip-content-right {
      left: -9px;
      &::after {
        left: 10px;
      }
    }
    &:hover,
    &:active {
      overflow: visible;
      .tooltip-title {
        color: ${color.transparentWhite8};
        border-color: ${color.transparentWhite8};
      }
      .tooltip-content {
        display: block;
        opacity: 1;
        color: ${color.transparentWhite8};
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    h1,
    .h1 {
      font-size: 1.5rem;
      &.big-header {
        font-size: 28px;
      }
    }
    h2,
    .h2,
    h3,
    .h3 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
      font-weight: ${font.mediumWeight};
    }

    h4,
    .h4,
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 20px;
      font-weight: ${font.mediumWeight};
    }

    .subheader {
      font-size: 1rem;
    }
    blockquote {
      padding: 25px 7%;
      font-size: 1.2rem;
    }
    p {
      font-size: 14px;
    }
    table {
      td,
      th {
        padding: 10px 15px 10px 0;
      }
    }
    .flex {
      .col-img {
        text-align: center;
      }
      .col-4 {
        flex-basis: 49%;
      }
    }
    .css-col-2,
    .css-col-3 {
      column-count: 1;
    }
    .tooltip {
      .mobile-left {
        left: auto;
        right: 0;
        &::after {
          left: auto;
          right: 5px;
        }
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex {
      .col-3 {
        flex-basis: 31%;
      }
    }
    .flex {
      .col-2 {
        flex-basis: 48%;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .flex {
      .col-2,
      .col-3 {
        flex-basis: 100%;
      }
    }
    .col-sm-6,
    .col-3,
    .col-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-img,
    .col-text {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      padding: 0;
    }
    .h3,
    h3,
    h2,
    .h2 {
      &.gradient,
      &.gradient-dark {
        span {
          padding: 3px 15px;
          font-size: 1.2rem;
          max-width: 70%;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .accordion-title {
      font-size: 1rem;
      &.half-width {
        max-width: 100%;
      }
    }
  }
`;

export const Slick = css`
  .slick-slider {
    position: relative;
    z-index: 0;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-loading .slick-list {
    background: ${color.black};
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 48px;
    height: 48px;
    z-index: 10;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: ${color.white};
    outline: none;
    background: transparent;
    opacity: 0.7;
    border: none;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus,
  .slick-next:active,
  .slick-next:active {
    color: transparent;
    outline: none;
    background: transparent;
    opacity: 1;
  }
  .slick-prev.slick-disabled .slick-next.slick-disabled {
    opacity: 0.25;
  }

  .slick-prev {
    left: 25px;
  }
  [dir='rtl'] .slick-prev {
    right: 25px;
    left: auto;
  }

  .slick-next {
    right: 25px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: 25px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    display: none;
    position: absolute;
    bottom: 6px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 30px;
    height: 26px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 10px;
    left: 0;
    width: 30px;
    height: 6px;
    content: '';
    text-align: center;
    border: 1px solid ${color.lightOrange};
    background: ${color.lightOrange};
    border-radius: 4px;
    opacity: 0.2;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    background-color: ${color.yellow};
    border-color: ${color.darkOrange};
    box-shadow: 0 3px 3px 0 #6e0c00, 0 0 5px 0 rgba(255, 192, 0, 0.42),
      inset 0 -1px 1px 1px rgba(93, 57, 0, 0.5), inset 0 -1px 2px 1px #ffab00;
    opacity: 1;
  }

  @media (max-width: ${breakpoints.sm}) {
    .slick-dots {
      display: block;
      bottom: -25px;
      padding-top: 5px;
    }
  }
`;

export const NavUlStyles = css`
  .mobile-only {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    .mobile-only {
      display: flex;
      padding: 20px 15px;
      justify-content: space-between;
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 48%;
        padding: 13px;
      }
      .header-promo-link {
        background: ${color.transparentWhite1};
        border: none;
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    &.open {
      -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        alternate both;
      animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate
        both;
      display: block;
      position: fixed;
      bottom: 48px;
      left: 0;
      width: 100%;
      height: calc(100% - 113px);
      padding: 0;
      border-top: 10px solid ${color.brownRed};
      background: ${color.brownRed};
      margin: 0;
      .top-nav-ul {
        display: block;
        max-height: 100%;
        overflow-y: auto;
        padding-right: 0;
      }
      .menu-item {
        margin: 0;
        position: relative;
        display: block;
      }
      .top-menu-link {
        position: relative;
        margin: 0;
        height: auto;
        padding: 14px 20px 0 65px !important;
        border-left: none;
        text-align: left;
        font-weight: ${font.mediumWeight};
        span {
          display: block;
          padding-bottom: 14px;
          border-bottom: 1px solid ${color.transparentWhite1};
        }
        svg {
          position: absolute;
          left: 14px;
          top: 5px;
          width: 46px;
          height: auto;
          padding: 12px;
          background: ${color.transparentWhite1};
          border-radius: 25px;
        }
      }
      .submenu {
        max-height: none;
        position: static;
        overflow-y: auto;
      }
    }
  }
`;

export const NavStylesNew = css`
  display: table;
  max-width: 1224px;
  width: 100%;
  margin: 0 auto 12px;
  clear: both;
  font-weight: ${font.mediumWeight};
  list-style: none;
  text-align: center;
  font-size: 1rem;
  .menu-item {
    display: table-cell;
  }
  .with-icon {
    & > a {
      text-align: right;
      padding-right: 5px;
    }
  }
  .top-menu-link {
    padding: 10px 2em;
    text-align: center;
    text-transform: uppercase;
    height: 45px;
    color: ${color.transparentWhite8};
    svg {
      margin-right: 10px;
    }
    &.active {
      color: #ffcdcd;
      text-shadow: 0 0 5px #d60000, 0 1px 4px #e02020;
      svg {
        path {
          fill: ${color.peach} !important;
        }
        filter: drop-shadow(1px 1px 6px ${color.peach});
      }
    }
    &:hover {
      color: #ffcdcd;
      text-shadow: 0 0 5px #d60000, 0 1px 4px #e02020;
      svg {
        path {
          fill: ${color.peach} !important;
        }
        filter: drop-shadow(1px 1px 6px #d60000), drop-shadow(0 1px 4px #e02020);
      }
    }
  }
  .right-line {
    position: relative;
    &::after {
      content: '|';
      color: ${color.transparentWhite2};
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
  .home .top-menu-link {
    text-align: left;
    padding-left: 5px;
  }
  .submenu {
    display: none;
  }
  li {
    text-align: left;

    a {
      display: block;
      color: ${color.white};
      position: relative;
    }
    a:hover,
    a:active,
    .active {
      text-decoration: none;
      color: ${color.white};
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &.open-item {
      .submenu {
        display: block;
      }
    }
  }
  .mobile-collapse-link {
    display: none;
  }

  @supports (-ms-ime-align: auto) {
    font-size: 1.125rem;
  }

  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
    a.active {
      color: #fff;
    }

    .mobile-collapse-link {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 9px;
      top: 13px;
      transform: rotate(0.75turn);
      pointer-events: none;
      svg {
        position: absolute;
        left: 19px;
        top: 15px;
      }
    }

    .open-item {
      .submenu {
        -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          alternate both;
        animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate
          both;
      }
      .mobile-collapse-link {
        transform: none;
        pointer-events: auto;
        top: 0;
        svg {
          left: 15px;
          top: 16px;
        }
      }
    }

    .with-icon {
      .mobile-collapse-link {
        opacity: 0;
      }
    }

    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
      }
      100% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
      }
    }
  }
`;

// top: 159px;
export const SubmenuStylesNew = css`
  position: absolute;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 100;
  min-width: 350px;
  max-height: 540px;
  overflow-y: auto;
  padding: 30px 5px;
  ul {
    display: flex;
    flex-flow: row wrap;
    max-width: 1244px;
    margin: 0 auto;
  }
  li {
    flex: 0 23%;
    margin: 0 1%;
    overflow: hidden;
    a {
      display: block;
      height: 100%;
      color: ${color.orange};
      padding: 10px;
      border-bottom: 1px solid ${color.transparentWhite2};
    }
  }
  .service-wrapper {
    display: none;
  }

  .submenu-item {
    a {
      color: ${color.transparentWhite8};
    }
    a:hover,
    a:active {
      color: ${color.white};
      .subitem-arrow {
        opacity: 1;
      }
    }
    .subitem-arrow {
      float: right;
      margin: 1px 0 0 10px;
      opacity: 0.2;
      svg {
        margin-right: 0;
      }
    }

    .subitem-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 95%;
      overflow: hidden;
      display: block;
    }

    .subitem-label {
      display: inline-block;
      vertical-align: top;
      margin: 0 7px;
      padding: 1px 7px;
      font-size: 12px;
      border-radius: 10px;
      background: ${color.white};
      color: ${color.red};
      text-transform: capitalize;
    }

    .subitem-icon {
      float: left;
      svg {
        margin-right: 1rem;
      }
    }

    .subitem-description {
      color: ${color.textGrey};
      font-size: 12px;
      display: block;
    }

    &.big-link {
      margin-bottom: 10px;
      a {
        padding: 20px 70px 20px 20px;
        border-bottom: none;
        border: 2px solid ${color.transparentWhite1};
      }
      .subitem-arrow {
        opacity: 1;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        line-height: 20px;
        border: 2px solid ${color.white};
        text-align: center;
        padding: 6px;
        position: absolute;
        top: 20px;
        right: 20px;
        float: none;
      }
      .subitem-title {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: static;
    max-height: 50vh;
    border-bottom: none;
    background: none;
    width: 100%;
    min-width: 250px;
    height: 100%;
    padding: 20px 10px 20px 62px;
    ul {
      max-height: 100%;
      display: block;
      overflow-y: auto;
    }
    li {
      display: block;
      text-align: left;
      a {
        padding-left: 0;
      }
    }
  }
`;

export const ContentStyles = css`
  .wrapper1250 {
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  h2,
  h3,
  h4 {
    color: ${color.orange};
  }
  p {
    margin: 15px 0;
    letter-spacing: 0.75px;
  }
  a:not(.button) {
    color: ${color.yellow};
  }
  ol {
    list-style: none;
    counter-reset: li;
    margin: 15px 0;
    padding: 0;
    li {
      counter-increment: li;
      margin-left: 20px;
      &::before {
        content: counter(li);
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
        color: ${color.white};
      }
    }
  }
  ul {
    list-style: none;
    font-weight: 600;
    letter-spacing: 0.75px;
    line-height: 160%;
    font-size: 16px;
    li {
      position: relative;
      padding-left: 25px;
      &::before {
        content: '•';
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        color: ${color.white};
        font-family: Verdana, Tahoma, sans-serif;
        font-size: 1rem;
      }
    }
  }

  .platform-buttons {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 0 -1%;
    .platform-button-link {
      display: block;
      margin: 0 1%;
      width: 23%;
      color: ${color.white};
      background: ${color.transparentWhite2};
      border-radius: 10px;
      padding: 20px 30px;
      opacity: 0.8;
      text-align: center;
      svg {
        max-width: 40%;
        max-height: 60px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        max-width: 50%;
      }
      &:hover,
      &:active {
        opacity: 1;
        text-shadow: 0 0 9px ${color.peach};
        text-decoration: none;
        svg {
          filter: drop-shadow(1px 1px 6px ${color.peach});
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .platform-buttons {
      .platform-button-link {
        width: 48%;
        padding: 20px 5px;
        margin: 5px 1%;
      }
    }
  }
`;

export const ContentStylesExt = css`
  .list-game {
    .casion-box {
      width: 49%;
      float: left;
      margin-right: 2%;
      &:nth-of-type(2n + 0) {
        margin-right: 0;
      }
    }
    & + div {
      clear: both;
    }
  }

  .casion-box {
    border: 1px solid ${color.transparentWhite1};
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 20px;
    h2 {
      margin-top: 0;
      font-size: 1.5rem;
    }
    &.gclub-bg-02 {
      display: flex;
      div {
        flex-basis: 33%;
        padding: 0 10px;
      }
      h3 {
        flex-basis: 100%;
      }
      p + img {
        max-width: 50%;
      }
      .register-game {
        flex-grow: 2;
      }
    }
  }

  .flex {
    .yellow-box {
      h2 {
        font-size: 1.2rem;
      }
      flex-basis: 31%;
      padding: 10px 15px;
      margin-bottom: 10px;
      border: 1px solid ${color.yellow};
      background: ${color.black};
      color: ${color.yellow};
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .list-game {
      .casion-box {
        width: 100%;
        float: none;
        margin-right: 0;
      }
    }
    .casion-box {
      &.gclub-bg-02 {
        display: block;
        p + img {
          max-width: 100%;
        }
      }
    }
    .flex {
      .yellow-box {
        flex-basis: 100%;
      }
    }
  }
`;

export const arrowBtn = css`
  content: '';
  position: absolute;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  vertical-align: middle;
`;
